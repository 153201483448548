import React from 'react'
import SwiperClinica from './swipers/SwiperClinica';

 
const Clinica = () => {

    return (
        <>
            <SwiperClinica/>
        </>
    )
}
export default Clinica;