import React, { Component } from 'react'
import GetImage from './GetImage';
import Spinner from './LAYOUT/Spinner'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';

class  Servicio extends Component {
    constructor(props){
        super(props)
    this.state =
     { 
        path: null,
        error:false,
        autenticado:false
     }
    }
     //recibe props automáticamente desde compose
    static getDerivedStateFromProps(props, state) {
        const { auth } = props;
        if (auth.uid)
            return { 
                autenticado: true
            }
        else
            return { autenticado: false }
    }
    //Eliminar libros
    eliminarRegistro = id => {
        const { firestore } = this.props;
        firestore.delete({
            collection: 'servicios',
            doc: id
        });
    }
    render() { 
        const {servicio,firebase} = this.props;
        const {autenticado} = this.state;
        if (!servicio) return <Spinner />
        return ( 
            <div className="container tecnicas">
                <div className="titulo col-12 display-4 text-center">{servicio.titulo}
                    {autenticado ?
                    <div>
                            <button
                                    className="btn btn-danger mr-2"
                                    onClick={() => this.eliminarRegistro(servicio.id)}>
                                    Eliminar {' '}
                                    <i className="fas fa-trash-alt"></i>
                            </button>
                            <Link to={`/libros/editar/${servicio.id}`} className="btn btn-primary">
                                <i className="fas fa-pencil-alt"></i> Editar Técnica
                            </Link>
                    </div>
                    :null}
                   
                </div>
                <div className="d-flex">
                    <div className="descripcion col-12 col-md-6">
                        <br/>
                        
                        <div className="subtitulo display-3">{servicio.subtitulo}</div>
                        <div className="texto">{servicio.descripcion.map((parrafo , index) =>(
                            <p key={index}>{parrafo}</p>
                            ))}
                        </div>
                    </div>
                    <div className="imagen  col-12 col-md-6">
                        <GetImage firebase={firebase} servicio={servicio}/>
                    </div>
                </div>
               
            </div>
            
         );
    }
}

export default compose(
    firestoreConnect([{ collection: 'servicios' }]),
    connect((state, props) =>
        ({
            auth: state.firebase.auth,
            //Así tendremos el contenido de servicios que está en firebase en el state
            servicios: state.firestore.ordered.servicios
        }))
)(Servicio);




