import React, { useState } from 'react'



const GetImage = (props) => {
    const [pathImg, setPathImg] = useState(false);
    const [error, setError] = useState(false)
    const pathImage = () =>{
        const { firebase ,servicio} = props;
        const ref = firebase.storage().ref(servicio.imagen);
            ref.getDownloadURL()
                .then((url) =>{
                    //this.setState({path:url,error:false}); 
                    setPathImg(url);
                    setError(false);
                })
                .catch((error) =>{
                        //this.setState({error:true}); 
                        setPathImg('');
                        setError(true);
                    });
    }
    pathImage();
    return (  
        error ? null : <img src={pathImg} alt="" width="70%"/>
    );
}
 
export default GetImage;