import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Spinner from './Spinner';
import MiDropDown from '../MiDropdown';
import Logo from '../LAYOUT/Logo';
import logo from '../../images/logo.jpg';







//import PropTypes from 'prop-types';

// 
class Encabezado extends Component {
    
        state = {
            autenticado: false,
            collapse:true
        }
    
    toggleNavbar = ()=>{
        this.setState(
            {collapse : !this.state.collapse}
        );
    }
   
    cerrarSesion = () => {
        const { firebase } = this.props;
        //history.push('/login');   
        this.toggleNavbar()
        firebase.logout();
        //return <Redirect to='/login'/> 
    }
   
    //recibe props automáticamente desde compose
    static getDerivedStateFromProps(props, state) {
        const { auth } = props;
        if (auth.uid)
            return { 
                autenticado: true
            }
        else
            return { autenticado: false }
    }
   

    render() {
    
        const { autenticado,collapse } = this.state;
        const { infonegocio , setInfo } = this.props;
        setInfo(infonegocio);
        //Esperar a que llegue información de tabla
        if(!infonegocio) return <Spinner/>
        const classDivNavBar = collapse ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classButton = collapse ? 'navbar-toggler collapsed' : 'navbar-toggler';
        const awesomeIcon = collapse ? <i className="fas fa-bars fa-2x"></i> : <i className="fas fa-sort-amount-up fa-2x"></i>

        return (
            <Fragment>
                <nav id="barra-menu" className="navbar fixed-top navbar-expand-md navbar-dark shadow">
                    {/* {autenticado ? null :
                    <InfoContacto
                        auth={auth}
                        whatsapp={infonegocio[0].whatsapp}
                    />}
                    */}
                 
                   
                  
                    <div className="container">
                       
                        <Link to={"./Contacto"}> 
                            <img  className="myLogo" src={logo} alt="Logo"/>
                        </Link>
                        <nav className="nombre-clinica navbar navbar-light">
                            <div>
                                <span className="navbar-brand col-8 m-0 p-0">
                                    
                                    <h1 className="text-light" style={{fontSize:40}}>SANIS
                                    
                                    <span className="text-light font-weight-bold" style={{fontSize:12}}>Fisioterápia</ span></h1>
                                </span>
                            </div>
                        </nav>
                        <Fragment>
                            <button     
                                className={classButton}
                                onClick={this.toggleNavbar}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#menu" 
                                aria-controls="menu" 
                                aria-expanded="false" 
                                aria-label="Menú principal">
                                <span>{awesomeIcon}</span>
                            </button>

                            <div className={classDivNavBar} id="menu">
                                {/* <Dropdown options={options} onChange={this._onSelect} value={defaultOption} placeholder="Select an option" /> */}
                                <ul className="navbar-nav mr-auto mt-auto">

                                    <li className="nav-item mi-boton" id="effect-button">  
                                        <div id="circle"></div>
                                        <Link onClick={this.toggleNavbar} to={'/'} className="nav-link 
                                        mb-1
                                        mb-md-0 mr-md-1">Home</Link>
                                    </li>
                                    <li className="nav-item mi-boton" id="effect-button">
                                        
                                        <div id="circle"></div>
                            
                                        <Link onClick={this.toggleNavbar} to={'/tecnicaslistado'} className="nav-link mb-1 mb-md-0 mr-md-1" >Servicios</Link>
                                    </li>
                                    <li className="nav-item mi-boton" id="effect-button">
                                        
                                        <div id="circle"></div>
                                        <Link onClick={this.toggleNavbar} to={'/aparatologialistado'} className="nav-link mb-1 mb-md-0 mr-md-1">Equipos</Link>
                                    </li>
                                    <li className="nav-item mi-boton" id="effect-button">
                                        <div id="circle"></div>
                                        <Link onClick={this.toggleNavbar} to={'/profesionaleslistado'} className="nav-link mb-1 mb-md-0 mr-md-1">Profesionales</Link>
                                    </li>
                                    
                                    <li className="nav-item mi-boton" id="effect-button">
                                        <div id="circle"></div>
                                        <a  href='http://fisioterapiasanis.simplesite.com' target="_blank" rel='noreferrer noopener' className="nav-link mb-1 mb-md-0 mr-md-1">Nuestro Blog</a>
                                    </li>
                                    <li className="nav-item mi-boton" id="effect-button">
                                        
                                        <div id="circle"></div>
                                        <Link onClick={this.toggleNavbar} to={'/contacto'} className="nav-link mb-md-0 mr-md-1">Contacto</Link>
                                    </li>
                                    {!autenticado ? null : 
                                        <li>
                                            <MiDropDown/>
                                                {/* <select value= {this.state.valueAdmin}  className="bg-transparent m-0 p-0 border-0 text-light">
                                                    <option value="upload" tag={Link} to="/login">Upload</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select> */}
                                          
                                            {/* <Link onClick={this.toggleNavbar} to={'/upload'} className="nav-link mb-md-0 mr-md-1">Upload</Link> */}
                                        </li>
                                    }
                                    {autenticado ? null : 
                                        <li className="nav-item mi-boton">
                                            <div id="circle"></div>
                                            <Link onClick={this.toggleNavbar} to={'/login'} className="btn btn-toolbar btn-warning"> <i className="fas fa-sign-in-alt fa-1x"></i></Link>
                                        </li>
                                    }
                                </ul>
                                {!autenticado ? null : 
                                    <ul className="navbar-nav">
                                        <li className="nav-item align-self-end">
                                            <button
                                                type="button"
                                                className="btn btn-toolbar btn-warning"
                                                onClick={this.cerrarSesion}>
                                                <i className="fas fa-sign-out-alt fa-1x"></i>
                                            </button>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </Fragment>
                    </div>
                </nav>
            </Fragment>
        );
    }
}


export default compose(
    firestoreConnect([
        {
            collection:'infonegocio'
        }
    ]),
    connect((state, props) => ({
        auth: state.firebase.auth,
        infonegocio: state.firestore.ordered.infonegocio
    }))
)(Encabezado)