import React, { Component } from 'react'
import Spinner from './Spinner';
import logo from '../../images/logo.jpg';

import { Link } from 'react-router-dom';
class  SocialNetBar extends Component {
    //state = {  }
    //
    

    render() {
        
       
    
    const { infoNegocio } = this.props;
    //Esperar a que llegue información de tabla
    if(!infoNegocio) return <Spinner/>
    
    const {direccion,poblacion,cp,provincia,twitter,whatsapp } = infoNegocio[0];
    const situacion = `${direccion}, ${poblacion}, ${cp}, ${provincia}` ; 
    //alert(infoNegocio[0]['whatsapp']);
    return ( 
        <nav id="social-net-bar" className="fixed-bottom bg-primary py-3 text-center">
            <div className="container text-light font-weight-bold">
                <div className="row justify-content-sm-between align-items-sm-center">
                    <div className="col-12 col-sm-6">
                       
                        <i className="fas fa-map-marked-alt mr-2"></i><span> {situacion}</span>
                       
                            
                    </div>
                    <div className="col-12 col-sm-auto">
                        <ul className="redes-sociales list-unstyled d-flex justify-content-center mb-0">
                            <li><a href={twitter}><i className="fab fa-twitter"></i></a></li>
                            <li><a href="!#"><i className="fab fa-facebook-f"></i></a></li>
                             <li><a href="!#"><i className="fab fa-instagram"></i></a></li>
                            <li><a href="!#"><i className="fab fa-youtube"></i></a></li>
                            <li><a href="www.google.com" target="_blank"><i className="fab fa-google"></i></a></li>
                             <li><i className="fab fa-whatsapp"></i><span> {whatsapp}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
     );
}
}
export default SocialNetBar;



      
   