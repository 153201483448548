import React from 'react'
import SwiperProfesionales  from './swipers/SwiperProfesionales';

const Profesionales = () => {
    return ( 
     
           <SwiperProfesionales/>
       
    );
}
 
export default Profesionales;