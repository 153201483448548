import React from 'react'
import SwiperServicios  from './swipers/SwiperServicios';


const Servicios = () => {
    return ( 
     
           <SwiperServicios/>
       
    );
}
 
export default Servicios;