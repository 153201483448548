import React from 'react';
import Swiper from 'react-id-swiper';
import Servicio from '../Servicio';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Spinner from '../LAYOUT/Spinner';
import PropTypes from 'prop-types';
  /*
    Punción seca
    Inducción miofascial
Osteopatía
Ejercicios de propiocepción y equilibrio
Cinesiterapia
Masoterapia
Kinesiotaping
Vendaje funcional
Movilización neuromeníngea
Estiramientos*/

const SwiperServicios = (props) => { 
    const { firebase } = props;
//Mostrar spinner mientras se carga la información
    if (!props.servicios) return <Spinner />
    //Params swiper
    
    const params = {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: {
        el: '.swiper-scrollbar'
      },
      mousewheel: true
    }
    return (
       <section className="servicios"> 
        <Swiper {...params}>
            {
              props.servicios.map((servicio,index) =>(
                <div key={index}>
                  <Servicio
                    key={servicio.id}
                    servicio={servicio}
                    firebase={firebase}
                  />
                </div>
              ))
            }
        </Swiper>   
      </section>  
    )
}

SwiperServicios.propTypes = {
    firestore: PropTypes.object.isRequired,
    servicios: PropTypes.array
}

export default compose(
    firestoreConnect([{ collection: 'servicios' }]),
    connect((state, props) =>
        ({//Así tendremos el contenido de servicios que está en firebase en el state
            servicios: state.firestore.ordered.servicios
        }))
)(SwiperServicios);

