import React from 'react'
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
// Version >= 2.4.0
import 'swiper/css/swiper.css';


const SwiperClinica = () => {

   const params = {
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay:5000,
        disableOnInteraction: true
      },
    pagination: {
      el: '.swiper-pagination',
      //type: 'progressbar',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    speed:1000,
    effect:"fade",
    grabCursor:true,
    keyboard:{
      enabled:true,
      onlyInViewport:true
    }
  }
    
    return (
     
      <div className="SwiperClinica">
      <Swiper {...params } >
         
             <div className="imgClinica container-fluid" style={{ backgroundImage:"url('../images/tu_eleccion_mas_saludable.jpg')",}}>
               
                <div className="capa-translucida h-100 w-100">
                  
                  <div className="container h-100">
                     
                     <div className="row h-75">
                         <div className="col-12 d-flex flex-column justify-content-center text-left">
                          
                            <div className="tituloInicio text-white">
                                TU <span className="tituloPlus text-primary font-weight-bold">SALUD</span> EN <br/>BUENAS MANOS
                            </div>
                            <Link to={"./Contacto"} className="btnCita">Reservar cita</Link>
                         </div>
                     </div>
                  </div>
                </div>
             </div>
             <div className="imgClinica" style={{ backgroundImage:"url('../images/tecno_avanzada.jpg')",}}>
               <div className="capa-translucida h-100 w-100">
                 <div className="container h-100">
                     <div className="row h-75">
                         
                         <div className="col-12 d-flex flex-column  justify-content-center text-md-left">
                            <div className="titulo text-white">
                                 TECNOLOGÍA <br/><span className="tituloPlus text-primary font-weight-bold text-md-right">AVANZADA</span>
                            </div>
                            <Link to={"./Contacto"} className="btnCita">Reservar cita</Link>
                         </div>
                    
                     </div>
                 </div>
                </div>
             </div>
             <div className="imgClinica" style={{ backgroundImage:"url('../images/rehabilitacion.jpg')",}}>
               <div className="capa-translucida h-100 w-100">
                 <div className="container h-100">
                     <div className="row h-75">
                         <div className="col-12 d-flex flex-column  justify-content-center text-md-left">
                            <div className="titulo text-white">
                                 UN EQUIPO DE EXPERTOS PENSANDO EN TU<br/><span className="tituloPlus text-primary font-weight-bold">REHABILITACIÓN</span>
                            </div>
                            <Link to={"./Contacto"} className="btnCita">Reservar cita</Link>
                         </div>
                     </div>
                 </div>
                </div>
             </div>
            
              
             <div className="imgClinica" style={{ backgroundImage:"url('../images/isma_gemelo.jpg')",}}>
               <div className="capa-translucida h-100 w-100">
                 <div className="container h-100">
                     <div className="row h-75">
                         <div className="col-12 d-flex flex-column text-md-left justify-content-center">
                            <div className="titulo display-4 text-white">
                                PROFESIONALES <br/><span className="tituloPlus display-3 text-primary font-weight-bold">ALTAMENTE</span> CUALIFICADOS.
                            </div>
                            <Link to={"./Contacto"} className="btnCita">Reservar cita</Link>
                         </div>
                     </div>
                 </div>
                </div>
             </div>
              <div className="imgClinica" style={{ backgroundImage:"url('../images/podologo.jpg')",}}>
               <div className="capa-translucida h-100 w-100">
                 <div className="container h-100">
                     <div className="row h-75">
                         <div className="col-12 d-flex flex-column text-md-left justify-content-center">
                            <div className="titulo display-4 text-white">
                                TAMBIÉN SERVICIO DE<br/><span className="tituloPlus display-3 text-primary font-weight-bold">PODOLOGÍA</span>
                            </div>
                            <Link to={"./Contacto"} className="btnCita">Reservar cita</Link>
                         </div>
                     </div>
                 </div>
                </div>
             </div>
             <div className="imgClinica" style={{ backgroundImage:"url('../images/portada_1.jpg')",}}>
                <div className="capa-translucida h-100 w-100">
                  <div className="container h-100">
                      <div className="row h-75">
                          <div className="col-12 d-flex flex-column text-md-right justify-content-center">
                              <div className="titulo display-4 text-white">
                                  SOMOS TU ELECCIÓN MÁS <span className="tituloPlus display-3 text-primary font-weight-bold">SALUDABLE</span> <br/>NO LO DUDES.
                              </div>
                              <Link to={"./Contacto"} className="btnCita">Reservar cita</Link>
                          </div>
                      </div>
                  </div>
                </div>
             </div>
            
           
     </Swiper>
     </div> 
     
     
        
        );
}
 
export default SwiperClinica;