import React, {useState} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import Spinner from './LAYOUT/Spinner';


const MiDropDown = (props) => {
    const {accionesMantenimiento} = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
   /*  const DropdownItemLink = props => {
        return <DropdownItem tag='a' href={props.to}>{props.title}</DropdownItem>;
    }; */
    //Esperar a que llegue información de tabla
    if(!accionesMantenimiento) return <Spinner/>
    //console.log(accionesMantenimiento);

    return ( 
        <Dropdown style={{ padding: '.5rem' , background: 'transparent'}} isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="text-light" style={{ padding: '.5rem' , background: 'transparent'}}>Administrar</DropdownToggle>
            
            <DropdownMenu className="dropDownMantenimiento" style={{ background: 'red' }}>
                {accionesMantenimiento.map((menu, index) => (
                <Link key={index} to={`/${menu.pagina}`} className="ml-3 bg-transparent text-light">{menu.accion}
                </Link>
                /* {<DropdownItem  className="bg-transparent text-light"  tag='a' href={`/${menu.carpeta}/${menu.pagina}`}>{menu.accion}
                </DropdownItem> }*/
            ) )}
               {/*  {[
                DropdownItemLink({ 
                    title: 'Clínica', 
                    to: '/',
                }), 
                DropdownItemLink({ 
                    title: 'Servicios', 
                    to: '/servicios',
                })
                ]} */}
            </DropdownMenu>
        </Dropdown>
     );
}
 
export default compose(
    firestoreConnect([
        {
            collection:'accionesMantenimiento'
        }
    ]),
    connect((state, props) => ({
        accionesMantenimiento: state.firestore.ordered.accionesMantenimiento
    }))
)(MiDropDown);


