import React, { Component} from 'react';
import logo from '../../images/logo.jpg';


class Logo extends Component {
    
   


    render() { 
        const item = [
          {
            id: 1,
            img: logo,
            className: 'img-fluid myLogo',
            alt: 'SALUD',
            target: '_blank',
            link: '/contacto'
          
          }
        ];
        return ( 
            
          item.map(item => { 
 
              // Es necesario colocar una 'key' a partir de la versión de React JS 16
              // colocamos el objeto 'id: 1' en <div key={ item.id } 
      
              return <a key={ item.id } href={ item.link }  target={ item.target }> 
                          <img className={ item.className } src={ item.img } alt={ item.alt } /> 
                      </a>        
          })
        )
         
    }
}
 
export default Logo;
