export const OBTENER_INFO_NEGOCIO = 'OBTENER_INFO_NEGOCIO';
export const OBTENER_INFO_NEGOCIO_EXITO = 'OBTENER_INFO__NEGOCIO_EXITO';
export const OBTENER_INFO_NEGOCIO_ERROR = 'OBTENER_INFO_NEGOCIO_ERROR';


export const VALIDAR_FORMULARIO = 'VALIDAR_FORMULARIO';
export const VALIDAR_FORMULARIO_ERROR = 'VALIDAR_FORMULARIO_ERROR';
export const VALIDAR_FORMULARIO_EXITO = 'VALIDAR_FORMULARIO_EXITO';


export const AGREGAR_PRODUCTO = 'AGREGAR_PRODUCTO';
export const AGREGAR_PRODUCTO_ERROR = 'AGREGAR_PRODUCTO_ERROR';
export const AGREGAR_PRODUCTO_EXITO = 'AGREGAR_PRODUCTO_EXITO';


export const COMEZAR_DESCARGA_PRODUCTOS = 'COMEZAR_DESCARGA_PRODUCTOS';
export const DESCARGA_PRODUCTOS_EXITOSA = 'DESCARGA_PRODUCTOS_EXITOSA';
export const DESCARGA_PRODUCTOS_ERROR = 'DESCARGA_PRODUCTOS_ERROR';

export const OBTENER_PRODUCTO_ELIMINAR = 'OBTENER_PRODUCTO_ELIMINAR';
export const PRODUCTO_ELIMINADO_EXITO = 'PRODUCTO_ELIMINADO_EXITO';
export const PRODUCTO_ELIMINADO_ERROR = 'PRODUCTO_ELIMINADO_ERROR';


export const OBTENER_PRODUCTO_EDITAR = 'OBTENER_PRODUCTO_EDITAR';
export const OBTENCION_PRODUCTO_EDITAR_EXITO = 'OBTENCION_PRODUCTO_EDITAR_EXITO';
export const OBTENCION_PRODUCTO_EDITAR_ERROR = 'OBTENCION_PRODUCTO_EDITAR_ERROR';

export const COMENZAR_PRODUCTO_EDITAR = 'COMENZAR_PRODUCTO_EDITAR';
export const EDITANDO_PRODUCTO_EXITO = 'EDITANDO_PRODUCTO_EXITO';
export const EDITANDO_PRODUCTO_ERROR = 'EDITANDO_PRODUCTO_ERROR';