import React from 'react'
import SwiperAparatologia from './swipers/SwiperAparatologia';


const Aparatologia = () => {
    return (
        <SwiperAparatologia/>
     );
}
 
export default Aparatologia;