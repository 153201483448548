import React, { Fragment , Component } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//REDUX
//STORE Y PROVIDER
//import store from './REDUX/store';
import { Provider } from 'react-redux';
import customReducer from './REDUX/Reducers';

//Para redirigir al usuario en función de si está autenticado
import { UserIsAuthenticated, UserIsNotAuthenticated } from './HELPERS/auth';

//COMPONENTES

//import UploadFile from './components/Upload'; 

import Login from './components/auth/Login';
import Clinica from './components/Clinica';
import Profesionales from './components/Profesionales';
import Servicios from './components/Servicios';
import NuevoServicio from './components/NuevoServicio';
import Aparatologia from './components/Aparatologia';
import Contacto from './components/Contacto';

import Cookies from './components/Cookies';
//Barras
import Encabezado from './components/LAYOUT/Encabezado';
import SocialNetBar from './components/LAYOUT/SocialNetBar';


//Nuevo-----------------------------
import thunk from 'redux-thunk';
//import firebase from 'firebase/app'
import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable
import { createStore, combineReducers, compose,applyMiddleware } from 'redux'
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const fbConfig ={
   apiKey:process.env.REACT_APP_API_KEY_FIREBASE,
    authDomain: "web-sanis.firebaseapp.com",
    databaseURL: "https://web-sanis.firebaseio.com",
    projectId: "web-sanis",
    storageBucket: "web-sanis.appspot.com",
    messagingSenderId: "432959032751",
    appId: "1:432959032751:web:d44352e348bc8d66ed735a",
    measurementId: "G-VMSP6LZCB4"
}

const rrfConfig={
  userProfile: 'users',
  useFirestoreForProfile: true// Firestore for Profile instead of Realtime DB
}

// Initialize Firebase
firebase.initializeApp(fbConfig);
firebase.firestore(); // <- needed if using firestore

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  customReducer:customReducer
})

// Create store with reducers and initial state
const initialState = {}
const middleware = [thunk];

const store = createStore(rootReducer, initialState,compose(
    //ReactReduxFirebaseProvider(firebase)//,
    applyMiddleware(...middleware)
   // ,
   // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
))

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}


class App extends Component {
  constructor(props){
    super(props);
    this.state={
      appInfo:null,
      date:new Date(),
    }
  }
  
  /* 
  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
    //console.log(this.state.date.toLocaleTimeString())
  }
   */




  setInfo= (appInfo) =>{
        this.setState({appInfo:appInfo})
  }
 

  render() { 
    
   

    return (
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <Fragment>
              <Router>
                <Encabezado setInfo={this.setInfo}/>
                <SocialNetBar infoNegocio={this.state.appInfo}/>
                  <Switch>
                    <Route exact path="/" component={Clinica}/>
                    <Route exact path="/tecnicaslistado" component={Servicios} />
                    <Route exact path="/tecnicasnuevo" component={UserIsAuthenticated(NuevoServicio)} />
                    <Route exact path="/profesionaleslistado" component={Profesionales} />
                    <Route exact path="/aparatologialistado" component={Aparatologia} />
                    <Route exact path="/contacto" component={Contacto} />
                    <Route exact path="/cookies.html" component={Cookies} />
                    <Route exact path="/login" component={UserIsNotAuthenticated(Login)}/>
                    {/*
                    <Route exact path="/upload" component={UserIsAuthenticated(UploadFile)} /> */}
                  </Switch>
              </Router>  
            </Fragment>
          </ReactReduxFirebaseProvider>
        </Provider>
      
    );
  }
}


export default App;
