import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//Para poder acceder a los métodos de firestore
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import MiAlert from './MiAlert'
import Spinner from './LAYOUT/Spinner';

 const initialState = 
        {
            descripcion:'',
            imagen:'',
            subtitulo:'',
            titulo:'',
            uploadValue:0,
            imageSelected:null,
            rutaImagen:'',
            message:'',
            imgVisible:false,
            error:null,
            msgError:''        
        };



class NuevoServicio extends Component {
    constructor(props){
        super(props);
        this.btnGuardar = React.createRef();
        this.fileInput = React.createRef();
        this.state = { 
            descripcion:'',
            imagen:'',
            subtitulo:'',
            titulo:'',
            uploadValue:0,
            imageSelected:null,
            rutaImagen:'',
            message:'',
            imgVisible:false,
            error:null,
            msgError:''    
        }
    }
    
    resetState = () =>{
        this.setState({...initialState})
        this.fileInput.current.value = "";
        this.btnGuardar.current.disabled=false;
    }
    leerDato = e => {
            this.setState({
                [e.target.name] : e.target.value
            })
    }
   
     agregarServicio = e =>{
        //Para que no se ejecute nada más cargar la página
        e.preventDefault();
        this.btnGuardar.current.disabled=true;
        this.uploadImage();
        //Asegurar información correctamente insertada
        const {descripcion} = this.state;
        const desc = descripcion.split('\n').filter(item => (item != null  && item !== ''));
        const nuevoServicio = {
            descripcion:desc,
            imagen:this.state.imagen,
            subtitulo:this.state.subtitulo,
            titulo:this.state.titulo
        };

        //Extraemos firestore de props
        const { firestore, history } =this.props;
        //Ejecutamos el metodo add de firestore, pasandole la collección/tabla
        firestore.add({collection:'servicios'},nuevoServicio)
            .then(() => {
                this.setState({
                    error:false,
                    msgError:`Técnica '${this.state.titulo}' registrada correctamente.`
                })
                //alert(`Técnica: ${this.state.titulo} registrada correctamente.`)
            }
        ).catch((error)=>{
            this.setState({
                error:true,
                msgError:error.message
            })
        })
            
            //.then(() => history.push('/tecnicaslistado'))
        
    }
    uploadImage= () =>{
        const {firebase} = this.props;
        const {imageSelected} = this.state;
        //const file = e.target.files[0];
       
        const storageRef = firebase.storage().ref(`imagenes/${imageSelected.name}`)
        const task= storageRef.put(imageSelected)
        task.on('state_changed', (snapshot)=>{
            let percentage =(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.setState({
                uploadValue : percentage
            })
        },(error) =>{
            this.setState({
                message:`Error en el proceso de Upload ${error.message}`
            })
        },() =>{
            task.snapshot.ref.getDownloadURL().then((url) =>{
                this.setState({
                    message:'Archivo subido',
                    rutaImagen: url
                })
            })
            
        })
    }
     handleOnChange = e =>{
        this.setState({
            imageSelected:e.target.files[0],
            imagen:`/imagenes/${e.target.files[0].name}`,
            rutaImagen:URL.createObjectURL(e.target.files[0]),
            imgVisible:true
        })
    }
       
       

     
    render() { 
       
        const img = document.getElementById("img");
        if (img){
            img.onload = function(){
                console.log('Ha entrado')
                URL.revokeObjectURL(this.src);
            }
        }
        //Esperamos hasta que la imagen ha subido a firestore
        if(this.state.uploadValue !== 100 && this.state.uploadValue !== 0) return <Spinner />
        const { imgVisible, error, msgError} = this.state;
        return (
            <div className="row"> 
                <div className="col-12 m-4 pt-5">
                    <Link to={'/tecnicaslistado'} className="btn bg-transparent text-white-50">
                        <i className="fas fa-arrow-circle-left text-light"></i> Técnicas disponibles
                    </Link>
                </div>
                <div className="col-12">
                    
                    <div className="row justify-content-center">
                        <div className="col-md-8 mt-5 text-white-50">
                            <div className="mb-4">
                                 <i className="fas fa-plus h2 text-white-50"> Agregar Nueva Técnica</i>
                                  {error? <MiAlert
                                    success={false}
                                    msgHead={"Error."}
                                    msg={msgError}/>
                                : error===false && error!==null?<MiAlert
                                    success={true}
                                    msgHead={"Técnica registrada correctamente."}
                                    msg={msgError}
                            />
                            :null
                            }
                            </div>
                            <form onSubmit={this.agregarServicio}>
                                <div className="form-group">
                                    <label>Nombre:</label>
                                    <input
                                        type="text"
                                        className="form-control 100vw"
                                        name="titulo"
                                        placeholder="Nombre"
                                        required
                                        onChange={this.leerDato}
                                        value={this.state.titulo}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Subtitulo:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subtitulo"
                                        placeholder="Subtitulo"
                                        required
                                        onChange={this.leerDato}
                                        value={this.state.subtitulo}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Descripcion:</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name="descripcion"
                                        placeholder="Descripción"
                                        required
                                        onChange={this.leerDato}
                                        value={this.state.descripcion}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Imagen:</label><br/>
                                    <input type="file" required className="btn btn-dark-green p-0" accept="image/*" name="file" ref={this.fileInput}  onChange={this.handleOnChange}/>
                                </div>
                                <div className="form-group">
                                    {this.state.message}
                                     <progress className=" w-100" value={this.state.uploadValue} max='100'></progress>
                                      {imgVisible
                                        ? <img width='100'  id="img" src={this.state.rutaImagen} alt="img"/>
                                        : null}
                                </div>
                                
                                <div className="form-group">
                                    <input
                                        type="submit"
                                        className="btn btn-outline-elegant bg-transparent text-light"
                                        value="Guardar"
                                        ref={this.btnGuardar} 
                                    />
                                     <input
                                        type="button"
                                        className="btn btn-outline-elegant bg-transparent text-light"
                                        value="Limpiar"
                                        
                                        onClick={() => this.resetState()}
                                    />
                                </div>
                            </form>
                           
                        </div>    
                    </div>
                </div>             
            </div>
        );
    
    
       }
}
 
export default firestoreConnect()(NuevoServicio);

