import {  OBTENER_INFO_NEGOCIO,
  OBTENER_INFO_NEGOCIO_EXITO ,
  OBTENER_INFO_NEGOCIO_ERROR } from '../Types';

  const initialState = {
      dataInfoNegocio : {whatsapp:'+34 630 01 14 13'},
      error:false,
      loading:false
  };

  export default function(state=initialState, action){
      switch(action.type){
            case OBTENER_INFO_NEGOCIO:
                return{
                    ...state,
                    loading:true
                }
            case OBTENER_INFO_NEGOCIO_EXITO:
                return{
                    ...state,
                    dataInfoNegocio:action.payload,
                    loading:false,
                    error:false
                }
            case OBTENER_INFO_NEGOCIO_ERROR:
                return{
                    ...state,
                    dataInfoNegocio:{},
                    loading:false,
                    error:true
                }
                default:return state
      }
  }